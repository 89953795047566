@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --pixel-size: 6;
}

.Character{
    width: calc(24px * var(--pixel-size));
    height: calc(24px * var(--pixel-size));
    position: relative;
    overflow: hidden;
}

.Character_spritesheet {
    animation: moveSpritesheet 4s steps(24) infinite;
    width: calc(576px * var(--pixel-size));
    position: absolute;
}

.Character_shadow {
    position: absolute;
    width: calc(24px * var(--pixel-size));
    height: calc(24px * var(--pixel-size));
 }

.pixelart {
    image-rendering: pixelated;
 }

@keyframes moveSpritesheet {
    from {
       transform: translate3d(0px,0,0)
    }
    to {
       transform: translate3d(-100%,0,0)
    }
 }